<template>
     <div class="container-fluid py-4 grade-detail  ">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
             <div class="row">
              <div class="col-lg-6">
              <h6 class="text-white text-capitalize ps-3">  التسجيل في عرض  : {{offer.name}}</h6>
              </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div class='row'>
              <ul class="list-group">
        <li
          class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
        >
         <h6 class="mb-3 text-sm"><i class="fas fa-star"></i> تفاصيل العرض <i class="fas fa-star"></i></h6>
          <div class="d-flex flex-column">
            <span class="mb-3 text-sm">
               الاسم:
              <span class="text-dark font-weight-bold ms-sm-2 text-sm"
                >{{offer.name}}</span
              >
            </span>
            <span class="mb-3 text-sm">
               مواد العرض:
              <span v-for="subject in offer.subjects" :key='subject.id' class="text-dark  ms-sm-2 font-weight-bold"
                >{{subject.name}} -</span
              >
            </span>
            <span class="text-sm">
              تكلفة العرض :
              <span class="text-dark  text-sm ms-sm-2 text-sm font-weight-bold">{{offer.cost}}</span>
            </span>
          </div>
        </li>
        </ul>
            </div>
            <div class="row align-center mx-2">
              <div class="mb-3 col-lg-5">
                     <label>  ادخل رقم الطالب أو الهاتف<span class="red">*</span></label>
                  <vmd-input
                    id=""
                    type="text"
                   label="  رقم الطالب أو الهاتف"
                   v-model="searchVal"
                   @keyup.enter="search"
                   required
                  />
              </div>
            </div>
            <div class="row align-center mx-2">
                  <h5 id='data'>بيانات الطالب</h5>
                 <div class="mb-3 col-lg-5">
                    <label> اسم الطالب </label>
                  <vmd-input
                    id=""
                    type="text"
                   v-model="student.full_name "
                   readonly
                  />
              </div>
                 <div class="mb-3 col-lg-5">
                    <label>الفرقة </label>
                  <vmd-input
                    id=""
                    type="text"
                   v-model="student.grade_name "
                  readonly
                  />
              </div>
                <div class="text-center col-lg-2">
                  <vmd-button
                  @click="reservation"
                  :disabled="triggerDisabled === 1"
                  style=" width: 100%;"
                    class="my-4 mb-2"
                    variant="gradient"
                    color="info"
                    > تسجيل
                  </vmd-button>
                </div>
                </div>
          </div>
        </div>
                <!-- current regesteriation -->
     <div class="row reseve-row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">    الطلاب المسجلين في عرض  : {{offer.name}} </h6>
            </div>
          </div>
          <div class="row enroll-row">
              <div class="col-md-4">
                <h6>عدد الطلاب  في العرض : {{enrolledCount}}</h6>
                </div>
                <div class="col-md-8 d-flex">
                 <h6 class="gr-text">عدد الطلاب  في المجموعات : </h6>
                <ul class="list-unstyled all-groups">
                  <li v-for="(count, group) in groupSizes" :key="group">
                    <span class="green group-num">مج {{group}} </span> : {{count}}
                  </li>
                  </ul>
                </div>
              </div>
          <div class="card-body px-0 pb-2">
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0 ">
                <thead>
                  <tr>
                     <th
                      class="text-uppercase   font-weight-bolder "
                    >
                      رقم الطالب
                    </th>
                    <th
                      class="text-uppercase   font-weight-bolder "
                    >
                      اسم الطالب
                    </th>
                      <th
                      class="text-uppercase   font-weight-bolder "
                    >
                       الفرقة
                    </th>
                       <th
                      class="text-uppercase  font-weight-bolder "
                    >
                       المجموعة
                    </th>
                    <th
                      class="text-uppercase   font-weight-bolder "
                    >
                        رقم الهاتف
                    </th>
                    <th
                      class="text-uppercase   font-weight-bolder "
                    >
                        إلغاء التسجيل
                    </th>
                  </tr>
                </thead>
                 <tbody>
                  <tr v-for='item in enrolledStudents ' :key="item.id" >
                    <td>
                     <p class=" text-s  mb-0">{{item.number}} </p>
                    </td>
                    <td>
                     <p class=" text-s  mb-0">{{item.full_name}} </p>
                    </td>
                     <td>
                     <p class=" text-s  mb-0"> {{item.grade.name??'-'}}   </p>
                    </td>
                      <td>
                     <p class=" text-s  mb-0">  {{item.group.name??'-'}}  </p>
                    </td>
                     <td>
                     <p class=" text-s  mb-0">  {{item.mobile}}  </p>
                    </td>
                    <td>
                      <button
                        type="button"
                        id="unroll"
                        @click="unenroll(item.id)"
                        class="btn  bg-gradient-danger pointer mx-1"
                        >إلغاء التسجيل</button
                      >
                    </td>
                  </tr>
                 </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
      </div>
    </div>
  <!-- start student offer unenroll modal-->
    <vue-final-modal
      :clickToClose="true"
      v-model="showModal"
      class='cancel-offer'
      name="example"
      classes="modal-container"
      content-class="modal-content"
    >
      <div class="card my-4">
        <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
          <div
            class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
          >
            <h6 class="text-white text-capitalize ps-3"> اختر المواد المستمر فيها </h6>
          </div>
        </div>
        <div class="card-body px-0 pb-2">
          <OfferSubjects v-model="offer"></OfferSubjects>
        </div>
        <div class="card-footer text-center">
          <button
            id="unroll"
            @click="submit(studentId)"
            class="btn bg-gradient-danger mx-1 col-4"
            >إلغاء التسجيل</button>
          <button
            id="unroll"
            @click="showModal = false"
            class="btn bg-gradient-info mx-1 col-4"
            >إلغاء </button>
        </div>
      </div>

    </vue-final-modal>
    <!-- end student offer unenroll modal-->
    </div>
</template>
<script>
import OfferSubjects from '@/components/OfferSubjects.vue'
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import { VueFinalModal } from 'vue-final-modal'
import swal from 'sweetalert'
import $ from 'jquery'
export default {
  name: 'OfferEnroll',
  components: {
    VueFinalModal,
    VmdButton,
    VmdInput,
    OfferSubjects
  },
  data () {
    return {
      searchVal: '',
      student: {},
      offer: {},
      enrolledStudents: [],
      courses: [],
      offerGrade: '',
      studentId: '',
      showModal: false,
      triggerDisabled: 0,
      enrolledCount: 0
    }
  },
  created () {
    let courses = []
    console.log(this.$route.params.id)
    this.$http.get(`${this.$hostUrl}offers/${this.$route.params.id}`).then(response => {
      this.offer = response.data.data
      this.enrolledStudents = response.data.data.students
      this.enrolledCount = this.enrolledStudents.length
      $.each(this.offer.subjects, function (key, val) {
        courses = val.courses
      })
      this.courses = courses
      this.offerGrade = this.offer.subjects[0].grade_id
      console.log(this.offer.subjects[0])
    })
  },
  computed: {
    groupSizes () {
      const groups = {}
      this.enrolledStudents.forEach((student) => {
        if (student.group.name in groups) {
          groups[student.group.name]++
        } else {
          groups[student.group.name] = 1
        }
      })
      return groups
    }
  },
  methods: {
    openModal () {
      this.showModal = true
    },
    search () {
      this.triggerDisabled = 0
      this.$http
        .get(`${this.$hostUrl}students-list?search=${this.searchVal}`)
        .then((response) => {
          this.student = response.data.data[0]
          if (response.data.data[0].grade_id !== this.offerGrade) {
            swal('الطالب لاينتمي لنفس الفرقة')
          }
        })
    },
    unenroll (studentId) {
      this.studentId = studentId
      this.showModal = true
      console.log(this.showModal, 'this.showModal')
    },
    async submit (id) {
      this.showModal = false
      swal({
        title: 'هل انت متأكد ؟',
        text: 'بمجرد الموافقة ، سيتم إلغاء التسجيل من العرض  !',
        icon: 'warning',
        buttons: ['لا', 'نعم'],
        dangerMode: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      }).then((willDelete) => {
        if (willDelete) {
          const formData = new FormData()
          formData.append('student_id', id)
          formData.append('offer_id', this.$route.params.id)
          console.log(this.offer.subjects)
          const subjects = []
          this.offer.subjects.forEach((subject) => {
            if (subject.checked === true) {
              subjects.push(subject.id)
            }
          })
          if (subjects.length) {
            formData.append('subject_id', subjects)
          }
          this.$http.post(this.$hostUrl + 'offer/unroll', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
            .then((response) => {
              if (!response.data.error) {
                console.log(response.data)
                swal({ title: response.data.message, buttons: 'تم' })
                this.$http.get(`${this.$hostUrl}offers/${this.$route.params.id}`).then(response => {
                  this.enrolledStudents = response.data.data.students
                  this.enrolledCount = this.enrolledStudents.length
                })
                this.student = {}
                this.searchVal = ''
              }
            }).catch(function (error) {
              if (Object.prototype.hasOwnProperty.call(error, 'response')) {
                this.triggerDisabled = 0
                swal({ title: error.response.data.message, buttons: 'تم' })
              }
            })
        } else {
          swal({ title: ' تم الإلغاء  !', buttons: 'تم' })
        }
      })
    },
    async reservation (e) {
      e.preventDefault()
      const current = this
      if (!this.student.id) {
        swal('يجب ادخال الطالب')
      } else {
        const formData = new FormData()
        formData.append('offer_id', this.$route.params.id)
        formData.append('student_id', this.student.id)

        this.errors = await this.$http.post(this.$hostUrl + 'offer/enroll', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then((response) => {
            if (!response.data.error) {
              this.triggerDisabled = 1
              swal({ title: response.data.message, buttons: 'تم' })
            }
            if (response.offer_id) { swal(response.offer_id[0]) }
            this.$http.get(`${this.$hostUrl}offers/${this.$route.params.id}`).then(response => {
              this.enrolledStudents = response.data.data.students
              this.enrolledCount = this.enrolledStudents.length
            })
            this.student = {}
            this.searchVal = ''
            console.log('Response', response)
            if (response.error) {
              swal({ title: response.message, buttons: 'تم' })
            }
          }).catch(function (error) {
            console.log('error', error)
            if (Object.prototype.hasOwnProperty.call(error, 'response')) {
              console.log('2')
              if (error.response.data.data) {
                current.errors = error.response.data.data
                swal(error.response.data.message)
              } else {
                swal({ title: error.response.data.message, buttons: 'تم' })
              }
              window.scrollTo(0, 0)
            }
          })
      }
    }

  }
}

</script>
<style >
  #data{
    background: #55ae59;
    color: #fff;
    padding: 13px;
    border-radius: 8px;
    margin: 30px 0px;
  }
  .reseve-row{
    margin-top: 70px !important;
  }
  .list-group{
    margin-bottom: 40px !important;
  }
  .fa-star{
    color: #fd9b16;
  }
  .third-r{
    margin: 40px 20px;
  }
  .third-r button{
    margin-top: 33px !important;
  }
  .paid-back{
  background: #baf2c5a1 !important;
  }
  .padding{
    padding:10px !important
  }
  .enroll-row{
  padding-top: 22px;
    padding-right: 22px;
  }
  .enroll-row  .pointer{
    color: blue !important
  }
  .group-num{
    font-weight: bold;
  }
  .all-groups li{
    display: inline-block;
    padding-right: 20px;
     padding-right: 35px;
  }
  .gr-text{
    margin-left: -53px;
  }
</style>
